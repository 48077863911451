// import react from 'react'


export const navLinks = [
    {
        id: 1,
        link: 'BasketBall',
        cName: 'navLink2',
    },
    {
        id: 2,
        link: 'FootBall',
        cName: 'navLink2',
    },
    {
        id: 3,
        link: 'BaseBall',
        cName: 'navLink2',
    },
    {
        id: 4,
        link: 'Golf',
        cName: 'navLink2',
    },
    {
        id: 5,
        link: 'Tennis',
        cName: 'navLink2',
    },
    {
        id: 6,
        link: 'Rugby',
        cName: 'navLink2',
    },
    {
        id: 7,
        link: 'VoleyBall',
        cName: 'navLink2',
    },
    {
        id: 8,
        link: 'Boxing',
        cName: 'navLink2',
    },
]