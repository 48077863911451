import { React, useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import Buttons from '../button/index.js';
import SignIn from './modalfields/signin.js';
import SignUp from './modalfields/signup.js'
import Cookies from 'universal-cookie';
import { getCustomer, getUserById } from '../../api'
import ContactModal from '../contactmodal/index.js';
import './style.css'
import { FaUser, FaUserPlus } from 'react-icons/fa'
import { RiErrorWarningFill } from 'react-icons/ri'
import Profile from './modalfields/profile/index.js';
import Deposit from './modalfields/deposite/index.js';
import WithDraw from './modalfields/withdraw/index.js';
import Transactions from './modalfields/transactions/index.js';
import History from './modalfields/history/index.js';


export default function Modale(props) {
    const cookie = new Cookies()
    const [message, setMessage] = useState('')
    const [user, setUser] = useState([])
    const [SupportModalShow, setSupportModalShow] = useState(false);
    const [isHistory, setIsHistory] = useState(false);


    useEffect(async () => {
        if (cookie.get('id')) {
            const user = getUserById(cookie.get('id'))
            user.then(response => {
                setMessage(response.data.message)
                setUser(response.data)
            })
        }
    }, [])

    return (
        <Modal
            {...props}
            size={isHistory ? "lg" : "md"}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            // backdrop="static"
            dialogClassName="border-radius-0"
        >
            {
                props.signin &&
                <>
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Sign In
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <SignIn />
                    </Modal.Body>

                </>
            }
            {props.signup &&
                <>
                    <Modal.Header >
                        <Modal.Title id="contained-modal-title-vcenter">
                            {
                                SupportModalShow ?
                                    <RiErrorWarningFill style={{ color: 'white' }} size={45} />
                                    :
                                    <>Sign Up</>
                            }
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <SignUp Close={props.onHide} setModalShow={props.setModalShow} SupportModalShow={SupportModalShow} setSupportModalShow={setSupportModalShow} />
                    </Modal.Body>

                </>
            }
            {
                props.message &&
                <>
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter" onClick={() => setSupportModalShow(false)}>
                            Warning
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            SupportModalShow ?
                                <ContactModal Close={props.onHide} setSupportModalShow={setSupportModalShow} />
                                :
                                <div className='warningBody'>
                                    <h5>
                                        {message}
                                    </h5>
                                    <br></br>
                                    <Button variant='primary' onClick={() => setSupportModalShow(true)} className='warningButton'>
                                        Contact Us
                                    </Button>
                                </div>
                        }
                    </Modal.Body>
                </>
            }


            {
                props.profile &&
                <>
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Profile
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Profile />
                    </Modal.Body>
                </>
            }
            {
                props.deposite &&
                <>
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Deposit
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Deposit />
                    </Modal.Body>
                </>
            }
            {
                props.withdraw &&
                <>
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Withdraw
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <WithDraw />
                    </Modal.Body>
                </>
            }
            {
                props.transaction &&
                <>
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Transactions
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Transactions />
                    </Modal.Body>
                </>
            }
            {
                props.contact &&
                <>
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Contact Us
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ContactModal Close={props.onHide} setSupportModalShow={setSupportModalShow} />
                    </Modal.Body>
                </>
            }
            {
                props.history &&
                <>
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">
                            History
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <History setIsHistory={setIsHistory} />
                    </Modal.Body>
                </>
            }
        </Modal>
    )
}
