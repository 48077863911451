import React from 'react'
import './styles.css'
import { reviews } from './reviews'
import { Col } from 'react-bootstrap'
import shuffledArray from '../../hook/shuffledArray'
import { FaUserCircle } from 'react-icons/fa';
const Reviews = () => {
    return (
        <div className='reviewsWrapper'>
            <h3 className='reviewsTitle mb-3'>Reviews</h3>
            <div className='reviewsContainer'>
                {
                    shuffledArray(reviews).map(review => (
                        <Col xxl={4} xl={4} lg={4} className='reviewContainer'>
                            <p className='reviewComment'><q> {review.comment} </q></p>
                            <div className='reviewNameContainer'>
                                <FaUserCircle size={35} />
                                <p className='reviewName'>{review.name}</p>
                            </div>
                            <span class="fa fa-star checked"></span>
                            <span class="fa fa-star checked"></span>
                            <span class="fa fa-star checked"></span>
                            <span class="fa fa-star checked"></span>
                            <span class="fa fa-star checked"></span>
                        </Col>
                    ))
                }
            </div>
        </div>
    )
}

export default Reviews