import React, { useEffect, useState } from 'react'
import './styles.css'
import Cookies from 'universal-cookie'
import { useFormik } from 'formik'
import { reduce, createTransaction, getUserById } from '../../../../api'
import { Col, Container, Row, Button, Form, InputGroup } from 'react-bootstrap'


function WithDraw() {
    const cookie = new Cookies()
    const [message, setMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const [amountValue, setAmountValue] = useState("");
    const [buttonClicked, setButtonClicked] = useState(false);

    const [user, setUser] = useState([])


    useEffect(
        () => {
            getUserById(cookie.get('id')).then(res => {
                setUser(res.data)
            }).catch(error => console.log(error));
        },
        [])

    const handleAmountChange = (e) => {
        setAmountValue(e.target.value);
    };

    const formik = useFormik({
        initialValues: {
            wallet: '',
            receiver: '',
            amount: ''
        },
        onSubmit: () => {
            const amount = amountValue;
            const receiver = formik.values.receiver;

            const reload = () => {
                window.location.reload()
            }


            const data = {
                sender: user._id,
                receiver: formik.values.receiver,
                amount: amountValue,
            }

            if ((receiver === "" && amount === "") || amount <= 0 || receiver === "" || amount === "") {
                setErrorMessage("Empty Fields or Negative Value!!");

            } else {
                if (amount <= user?.balance) {
                    if (!buttonClicked) {
                        setButtonClicked(true)
                        const func = createTransaction(data)
                        func.then((response) => {
                            if (response.success) {
                                setMessage("Your order has been processed, awaiting approval!! Kindly check transaction page.");
                                reduce(cookie.get('id'), amount)
                                setTimeout(reload, 3000);
                            } else {
                                alert("error")
                            }
                        })
                    }
                } else {
                    setErrorMessage("Insufficent Balance!!");
                }
            }



        }
    })

    return (
        <Container>
            <Row xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}  >
                    <div className='widthDrawWrapper'>
                        <div style={{ paddingLeft: 16, paddingRight: 16 }}>
                            {
                                message ?
                                    <div className='successMessageContainer'>
                                        <p>{message}</p>
                                    </div>
                                    :
                                    null
                            }
                        </div>
                        <div style={{ paddingLeft: 16, paddingRight: 16 }}>
                            {
                                errorMessage ?
                                    <div className='errorMessageContainer' id="message">
                                        <p>{errorMessage}</p>
                                    </div>
                                    :
                                    null
                            }
                        </div>
                        <div className='formWrapper'>
                            <Form onSubmit={formik.handleSubmit} className="formContainer" >
                                <Form.Group className="mb-3" controlId="formBasicFirstName">
                                    <h5 className='title mb-3'>Wallet Adress</h5>
                                    <Form.Control
                                        type="text"
                                        value={user?.wallet}
                                        disabled
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="username">
                                    <h5 className='title mb-3'>Receiver</h5>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="123"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.receiver || ''}
                                        name="receiver"
                                        id="receiver"
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="username">
                                    <h5 className='title mb-3'>Amount</h5>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            required
                                            type="number"
                                            placeholder="123"
                                            onChange={handleAmountChange}
                                            value={amountValue}
                                            name="amount"
                                            id="amount"
                                        />
                                        <Button onClick={() => setAmountValue(user.balance)} className="maxButton">
                                            MAX
                                        </Button>
                                    </InputGroup>
                                </Form.Group>
                                <div className='withdrawSendContainer'>
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        className="withdrawSubmitButton">Withdraw</Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </Col>
            </Row>

        </Container>
    )
}

export default WithDraw