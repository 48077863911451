import { Carousel, Container } from 'react-bootstrap'
import React from 'react'
import './style.css'



export default function Slideshow() {
    return (
        <Container className='carouselContainer' fluid style={{ padding: 0 }}>
            <Carousel indicators={false} interval={2000} style={{ width: '100%' }}>
                <Carousel.Item >
                    <div
                        className=" carouselItem"
                        style={{
                            backgroundImage: `url(${require("../../assets/images/image7-min.jpg")})`,
                        }}
                    ></div>
                </Carousel.Item>
                <Carousel.Item >
                    <div
                        className=" carouselItem"
                        style={{
                            backgroundImage: `url(${require("../../assets/images/image1-min.jpg")})`,
                        }}
                    ></div>
                </Carousel.Item>
                <Carousel.Item >
                    <div
                        className=" carouselItem"
                        style={{
                            backgroundImage: `url(${require("../../assets/images/image2-min.jpg")})`,
                        }}
                    ></div>
                </Carousel.Item>
                <Carousel.Item >
                    <div
                        className=" carouselItem"
                        style={{
                            backgroundImage: `url(${require("../../assets/images/image3-min.jpg")})`,
                        }}
                    ></div>
                </Carousel.Item>
                <Carousel.Item >
                    <div
                        className=" carouselItem"
                        style={{
                            backgroundImage: `url(${require("../../assets/images/image4-min.jpg")})`,
                        }}
                    ></div>
                </Carousel.Item>
                <Carousel.Item >
                    <div
                        className=" carouselItem"
                        style={{
                            backgroundImage: `url(${require("../../assets/images/image5-min.jpg")})`,
                        }}
                    ></div>
                </Carousel.Item>
                <Carousel.Item >
                    <div
                        className=" carouselItem"
                        style={{
                            backgroundImage: `url(${require("../../assets/images/image6-min.jpg")})`,
                        }}
                    ></div>
                </Carousel.Item>
            </Carousel>
        </Container >

    )
}

