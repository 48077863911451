export const footerLinks = [
    {
        title: 'leagues',
        link1: 'mlv',
        link2: 'mlr',
        link3: 'nfl',
        link4: 'nwsl',
    },
    {
        title: 'leagues',
        link1: 'mls',
        link2: 'nba',
        link3: 'nhl',
        link4: 'mlb',
        link5: 'mlu',
    },
    {
        title: 'sports',
        link1: 'BasketBall',
        link2: 'FootBall',
        link3: 'BaseBall',
        link4: 'Golf',
    },
    {
        title: 'sports',
        link1: 'Tennis',
        link2: 'Rugby',
        link3: 'VoleyBall',
        link4: 'Boxing',
    }

]