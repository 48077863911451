import axios from 'axios';

// const URL = "https://punterwinner.com";

export const login = async (username, password) => {
    const response = await axios.post('/auth/users/login', {
        username,
        password
    });
    if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
        return response.data;
    }
}

export const getCusotmers = async () => {
    const response = await axios.get('/users');
    if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
        return response.data
    }
}

export const register = async (data) => {
    const response = await axios.post('/auth/users/register', data);
    if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
        return response.data
    }
}

export const getNews = async () => {
    const response = await axios.get('/api/news');
    if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
        return response.data
    }
}
export const contactUs = async (email, message) => {
    const response = await axios.post('/api/contactus', {
        email,
        message
    });
    if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
        return response.data;
    }
}
export const getResults = async () => {
    const response = await axios.get('/api/sports');
    if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
        return response.data
    }
}
export const getUserById = async (id) => {
    const response = await axios.get('/users/' + id);
    if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
        return response.data
    }
}
export const editUser = async (id, data) => {
    const response = await axios.put(`/users/${id}`, data);
    if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
        return response.data;
    }
}
export const reduce = async (id, amount) => {
    const response = await axios.post('/api/reduce', {
        id,
        amount,
    });
    if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
        return response.data;
    }
}

export const createTransaction = async (data) => {
    const response = await axios.post('/api/send', data);
    if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
        return response.data;
    }
}

export const getTransactions = async (id) => {
    const response = await axios.get('/api/transactions/' + id);
    if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
        return response.data
    }
}

export const getHistories = async (user) => {
    const response = await axios.get('/histories');
    if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
        return response.data
    }
}