import React from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap'
import moment from 'moment';
import './style.css'

export default function UserTable(props) {

    return (
        <>
            <Container fluid className='tableContainer'>
                <Row style={{ padding: 0 }}>
                    <Col style={{ padding: 0 }}>
                        <h3 className='tableSportsTitle mb-3'>{props.title}</h3>
                    </Col>
                    <Row className='gameTableContainer' >
                        <Table responsive >

                            <thead>
                                <tr className="tableHead" >
                                    <th>Date</th>
                                    <th>Home</th>
                                    <th>Away</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    props.data?.map((data, index) => (
                                        <tr key={index} className='tableRow'>
                                            <td style={{ fontWeight: 500, fontSize: 14 }}>
                                                {moment().format('dddd DD MMMM yyyy')}
                                            </td>
                                            <td>
                                                <p style={{ fontSize: 14, lineHeight: 1.3, fontWeight: 500, margin: 0 }}>{data?.home_team}</p>
                                            </td>
                                            <td>
                                                <p style={{ fontSize: 14, lineHeight: 1.3, fontWeight: 500, margin: 0 }}>{data?.away_team}</p>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Row>
                </Row>
            </Container>
        </>
    )
}
