import React, { useState } from 'react'
// import { Col, Container, Row } from 'react-bootstrap'
import './style.css'
import { types } from './types'
import Modale from '../modal';
import Cookies from 'universal-cookie';
import { Col, Row } from 'react-bootstrap';



export default function BetTypes() {
    const [modalShow, setModalShow] = useState(false);
    const cookie = new Cookies();

    return (
        <>
            <div className='types'>
                <h3 className='betTypes mb-3'>
                    Bet Types
                </h3>
                <Row lg={12} md={12} sm={12} xs={12}>
                    {types?.map((data) =>
                        <Col lg={3} md={4} sm={6} xs={12}>
                            <div className='betTypesContainer' onClick={() => setModalShow(true)}>
                                <div style={{ marginBottom: 4 }}>
                                    {data.icon}
                                </div>
                                <p key={data.id} style={{ cursor: 'pointer' }}>{data.title}</p>
                            </div>
                        </Col>

                    )}
                </Row>
            </div>
            {
                cookie.get('token') ?
                    cookie.get('messageStatus') === '0' ?
                        null
                        :
                        <Modale
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            message={true}
                        />
                    :
                    <Modale
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        signin={true}
                    />

            }
        </>

    )
}
