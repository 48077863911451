import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';


import Cookies from 'universal-cookie';
import Modale from '../modal';
import { footerLinks } from './data';
import './style.css';

export default function Footer() {
    const [modalShow, setModalShow] = useState(false);
    const cookie = new Cookies();



    return (
        <Container className='footerWrapper'>
            <Container>
                <Row className="linksContainer mb-3" sm={3} md={3} xs={2} >
                    <Col lg={2} md={3} sm={12} className="socialMediaWrapper" >
                        <Row className='socialMediaContainer'>
                            <h3>Social Media</h3>
                        </Row>
                    </Col>
                    <Col lg={6} md={2} sm={12}></Col>
                    <Col lg={4} md={7} sm={12}>
                        <p className='footerText'>
                            18+ Know When To Stop. Don’t Go Over The Top. Gamble Responsibly. Think! About Your Choices. Check Gambling Help on www.gamblinghelponline.org.au
                        </p>
                    </Col>
                </Row>
                <Row xxl={12} xl={12} lg={12}>
                    {
                        footerLinks.map((link) => (
                            <Col xxl={3} xl={3} lg={3} md={6} sm={12} xs={12}>
                                <h5 className='footerLinkTitle'>{link.title}</h5>
                                <p className='footerLinks' onClick={() => setModalShow(true)}>{link.link1}</p>
                                <p className='footerLinks' onClick={() => setModalShow(true)}>{link.link2}</p>
                                <p className='footerLinks' onClick={() => setModalShow(true)}>{link.link3}</p>
                                <p className='footerLinks' onClick={() => setModalShow(true)}>{link.link4}</p>
                                <p className='footerLinks' onClick={() => setModalShow(true)}>{link?.link5}</p>
                            </Col>
                        ))
                    }
                </Row>
            </Container>
            <div className="copyRight" >
                <p style={{ margin: 0, paddingTop: 0 }}>Copyright © 2023 - All right reserved.</p>
            </div>
            {
                cookie.get('token') ?
                    cookie.get('messageStatus') === '0' ?
                        null
                        :
                        <Modale
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            message={true}
                        />
                    :
                    <Modale
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        signin={true}
                    />

            }
        </Container>
    )
}



