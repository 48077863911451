export const reviews = [
    {
        name: "John Doe",
        rating: 5,
        comment: "I've been using this sports betting website for a while now, and it's been a great experience. The user interface is intuitive, and I appreciate the wide range of sports and markets they offer. Highly recommended!"
    },
    {
        name: "Alice Smith",
        rating: 4,
        comment: "I'm new to sports betting, and this site has been a fantastic introduction. They provide helpful guides and tips, and the live betting feature is super exciting. I've already made some good wins!"
    },
    {
        name: "David Johnson",
        rating: 5,
        comment: "One of the things I love about this sports betting site is the quick and responsive customer support. They're always there to assist with any issues or questions I have. It adds a lot of value to the platform."
    },
    {
        name: "Emily Wilson",
        rating: 4.5,
        comment: "The odds on this site are competitive, and the payouts have been smooth and hassle-free. I appreciate the reliability, and it's become my go-to place for sports betting."
    },
    {
        name: "Michael Brown",
        rating: 5,
        comment: "The mobile app is fantastic! It's easy to navigate, and I can place bets on the go. Plus, they offer live streaming for many events, making it even more exciting."
    },
    {
        name: "Sarah Davis",
        rating: 4.2,
        comment: "I've had a great experience with this sports betting website. The variety of sports they cover is impressive, and their in-play betting feature keeps things thrilling."
    },
    {
        name: "James White",
        rating: 3.5,
        comment: "Decent sports betting site, but I wish they had more promotions and bonuses for loyal customers. The odds are good, though."
    },
    {
        name: "Laura Miller",
        rating: 4.7,
        comment: "I love the cash-out feature! It's saved me from some potential losses. The site is user-friendly, and I appreciate the fast withdrawals."
    },
    {
        name: "Robert Taylor",
        rating: 3.8,
        comment: "The customer service is hit or miss. Sometimes it's excellent, other times not so much. Betting options are solid, though."
    },
    {
        name: "Elizabeth Brown",
        rating: 4.5,
        comment: "I've had a profitable run here. The platform is reliable, and I've never had any issues with payments or withdrawals."
    },
    {
        name: "Daniel Clark",
        rating: 4.2,
        comment: "A good sports betting site with a wide range of markets. The live chat support is responsive and helpful when needed."
    },
    {
        name: "Jennifer Turner",
        rating: 3.0,
        comment: "Average sports betting site. The interface could use some improvement, but it gets the job done."
    },
    {
        name: "William Harris",
        rating: 4.8,
        comment: "This site has some of the best odds I've seen. I've been consistently winning, and payouts are fast.Average sports betting site. The interface could use some improvement, but it gets the job done."
    },
    {
        name: "Olivia Anderson",
        rating: 4.6,
        comment: "I appreciate the educational resources they provide for beginners. It helped me get started with sports betting.Solid sports betting platform. The app works well, and I've never experienced downtime."
    },
    {
        name: "Matthew Lee",
        rating: 4.0,
        comment: "Solid sports betting platform. The app works well, and I've never experienced downtime.I appreciate the educational resources they provide for beginners. It helped me get started with sports betting."
    }
]
