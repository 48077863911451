import { Container, Row, Col } from 'react-bootstrap';
import './App.css';
import Navigation from './components/navigation'
import Slideshow from './components/slideshow';
import TableContainer from './components/table/tableContainer';
import Footer from './components/Footer';
import News from './components/news';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { getResults } from '../src/api'
import BetTypes from './components/types';
import Reviews from './components/reviews';


function App() {
  const [news, setNews] = useState([]);
  const [games, setGames] = useState([]);

  useEffect(() => {
    axios.get('/api/news')
      .then(response => {
        setNews(response.data.items);
      })
      .catch(error => console.log(error));

    getResults().then(async response => {
      if (response) {
        setGames(response)
      }
    })
      .catch(error => console.log(error));
  }, []);


  return (
    <div style={{ overflowX: 'hidden' }}>
      <Navigation />
      <Row>
        <Col>
          <Slideshow />
        </Col>
      </Row>
      <br></br>
      <Container>
        <Row>
          <Col lg={12} md={12}>
            <News news={news} setNews={setNews} />
          </Col>
          <Col lg={12} md={12} sm={12}>
            <BetTypes />
          </Col>
          <Col lg={12} md={12} sm={12}>
            <Row>
              <TableContainer games={games} />
            </Row>
          </Col>
          <Col lg={12} md={12} sm={12}>
            <Reviews />
          </Col>
        </Row>
      </Container>
      <Row>
        <Footer />
      </Row>
    </div >
  );
}

export default App;
