import { Button, Container } from 'react-bootstrap';
// import Cookies from 'universal-cookie';
import './style.css'
// import useViewport from "../../hook/useViewport";
// import { useState } from 'react';
import moment from 'moment';
// import Modale from '../modal';


export default function News({ news }) {
    // const cookie = new Cookies();
    // const { width } = useViewport();
    // const [modalShow, setModalShow] = useState(false);


    return (
        <>
            <Container style={{ padding: 0 }}>
                <h3 className='NewsBigTitle mb-3'>Today News</h3>
                <div className='newsWrapper' >
                    {news?.map((data, index) => (
                        <div key={index} className='newsContainer'>
                            <div className="imageDateCatContainer">
                                <img className='newsImage' src={data.enclosures[0].url} alt="news" />
                                <div className='dateCatContainer'>
                                    <p className='newsDate' >{moment(data.published).format('L HH:MM')}</p>
                                    <p className='newsCat' >{data.category}</p>
                                </div>
                            </div>
                            <p className="newsTitle" style={{ fontSize: 12, lineHeight: 1.3, height: '4rem' }}>{data.title}</p>
                            <div className='readMoreButtonContainer'>
                                <Button className='readMoreButton'>
                                    <a
                                        href={data.link}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Read More
                                    </a>
                                </Button>
                            </div>
                        </div>
                    ))
                    }
                    {/* <Modale
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        signin={cookie.get('token') ? null : true}
                    /> */}
                </div>
                <br></br>
            </Container>
        </>
    )
}
