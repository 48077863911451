// import { Popover } from 'bootstrap';
import React, { useState } from 'react'
import { Nav, Navbar, Container, Dropdown } from 'react-bootstrap'
import './style.css';
import DropDown from '../dropdown';
import Buttons from '../button';
import Cookies from 'universal-cookie';
import Modale from '../modal';
import { navLinks } from './navLinks';
import useViewport from "../../hook/useViewport";

export default function Navigation() {
    const cookie = new Cookies();
    const [modalShow, setModalShow] = useState(false);
    const { width } = useViewport();
    //className={(width < 600 ? 'mobileNewsContainer' : 'newsContainer')}

    return (
        <Container className="navContainer" fluid >
            <Container className={(width < 767 ? null : 'navWrapper')}>
                <Navbar expand="md" style={{ padding: 0, }}>
                    <Navbar.Brand href="/">
                        <div className="logo"></div>
                    </Navbar.Brand>
                    <Navbar.Toggle className="hamburgerButton" aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav className="me-auto" ></Nav>
                        <Nav
                            className=" my-2 my-lg-0 linkContainer"
                        >
                            {
                                !cookie.get('token') &&
                                navLinks?.map(link => (
                                    <Nav.Link className={link.cName} onClick={() => setModalShow(true)}>{link.link}</Nav.Link>
                                ))
                            }
                        </Nav>
                        {
                            cookie.get('token') ?
                                <>

                                    <Buttons text='History' kind="transparent" history={true} />
                                    <Buttons text='Deposit' kind="transparent" deposite={true} />
                                    <Buttons text='Withdraw' kind="orange" withdraw={true} />
                                    <Buttons text='Transactions' kind="transparent" transaction={true} />
                                    <Buttons text='profile' kind="transparent" profile={true} />
                                    <DropDown />

                                </>
                                :
                                <>
                                    <Buttons text='sign in' kind="blue" signin={true} />
                                    <Buttons text='create Account' kind="orange" signup={true} />

                                </>

                        }

                    </Navbar.Collapse>
                    {
                        !cookie.get('token') &&
                        <Modale
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            signin={true}
                        />
                    }
                </Navbar >
            </Container>
        </Container >
    )
}
